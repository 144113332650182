<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.creditRefundReport')">
      <form v-on:submit.prevent="getCreditRefundReport">
        <div class="row">
          <div class="col-sm-6 col-md-4">
             {{ $t('message.machineIdFarm') }} :
            <br>
            <model-list-select
              :list="machineData"
              v-model="searchParams.machineId"
              option-value="machineId"
              option-text="name"
              placeholder="เลือกรหัสร้าน"
            ></model-list-select>
          </div>
          <div class="col-sm-6 col-md-2">
            {{ $t('message.date') }} :
            <br>
            <date-picker
              type="datetime"
              v-model="searchParams.datetime"
              lang="en"
              range
              format="yyyy-MM-dd"
            ></date-picker>
          </div>
          <div class="col-sm-6 col-md-4">
            <br>
            <b-btn variant="primary" type="submit">
              <i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}
            </b-btn>&nbsp;&nbsp;&nbsp;
            <!-- <b-btn variant="danger" @click="createReport()">
              <i class="fa fa-plus-square"></i>&nbsp;{{ $t('message.create') }}
            </b-btn> -->
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align-last: center">
            <tr>
              <th>{{ $t('message.machineIdFarm') }}</th>
              <th>{{ $t('message.refNumber') }}</th>
              <th>{{ $t('message.date') }}</th>
              <th>{{ $t('message.note') }}</th>
              <th style="text-align-last: right">{{ $t('message.amount') }}</th>
              <th style="text-align-last: right">{{ $t('message.oldCredit') }}</th>
              <th style="text-align-last: right">{{ $t('message.all') }}</th>
              <th>{{ $t('message.createBy') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index, key) in rowData.rows">
              <tr style="text-align: center">
                <td>{{item.machineId}}</td>
                <td>{{item.refNumber}}</td>
                <td>{{item.createdAt | moment('YYYY-MM-DD HH:mm:ss')}}</td>
                <td>{{item.details.note}}</td>
                <td style="text-align: right">{{ item.type === 'addCredit' ? '+' : '-'}} {{item.price}}</td>
                <td style="text-align: right">{{item.creditCMBefore}}</td>
                <td style="text-align: right">{{item.creditCMAfter}}</td>
                <td>{{item.details.createBy}}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <br>
        <b-pagination size="md" :total-rows=totalRows v-model="pagination" :per-page=itemperPage @input="pageChanged(pagination)"></b-pagination>
        <!-- <b-pagination
          size="md"
          :total-rows="totalRows"
          v-model="pagination"
          :per-page="itemperPage"
          @input="pageChanged(pagination)"
        ></b-pagination>-->
      </div>
    </b-card>
  </div>
</template>


<script>
import moment from "moment";
import webServices from "../../script";
import DatePicker from "../../custom_modules/vue2-datepicker";
import {
  ModelListSelect,
  MultiListSelect
} from "../../custom_modules/search-select";
export default {
  name: "Reports",
  components: {
    MultiListSelect,
    ModelListSelect,
    DatePicker
  },
  data() {
    return {
      rowData: [],
      modalData: {},
      now: new Date(),
      machineData: [],
      searchParams: {
        type: ["credit", "addCredit", "deductCredit"],
        paymentType: "REFUND",
        datetime: [
          moment()
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss"),
          moment()
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss")
        ],
        page: 1,
        rows: 20
      },
      newReportData: {},
      selectedMachine: [],
      status: [
        { value: "SUCCESS", text: "แก้ไขแล้ว" },
        { value: "PROCESSING", text: "กำลังดำเนินการ" },
        { value: "RECEIVED", text: "ได้รับแจ้งปัญหาแล้ว" }
      ],
      options: [
        { value: true, text: "ในประกัน" },
        { value: false, text: "นอกประกัน" }
      ],
      totalRows: 0,
      itemperPage: 20,
      pagination: 1,
    };
  },
  methods: {
    getMachine() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then(res => {
          this.$Progress.finish();
          this.machineData = res.data;
          // console.log(res.data)
        })
        .catch(err => {
          this.$Progress.fail();
          console.log("error @get Macinhe");
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด"
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
    getCreditRefundReport() {
      this.$Progress.start();
      if (this.searchParams.datetime !== "") {
        var timeEnd = moment(this.searchParams.datetime[1]).format("HH:mm:ss");
        if (timeEnd === "00:00:00" || timeEnd === "12:00:00 AM") {
          this.searchParams.datetime[1].setHours(23, 59, 59, 999);
        }
        this.searchParams.from = moment(this.searchParams.datetime[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.searchParams.to = moment(this.searchParams.datetime[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        // console.log(this.todate)
      } else {
        this.fromdate = "";
        this.todate = "";
      }

      webServices
        .getTransactionV2(this.searchParams)
        .then(res => {
          if (res.data) {
            this.$Progress.finish();
            this.rowData = res.data;
            this.totalRows = res.data.count
          } else {
            this.$Progress.fail();
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
        })
        .catch(err => {
          this.$Progress.fail();
          console.log("error @get Report");
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด"
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
    createReport() {
      this.$router.push("/user/customercredit");
    },
    pageChanged (PageNum) {
      // window.scrollTo(0, 0)
      this.searchParams.page = PageNum
      this.getCreditRefundReport()
    },
  },
  mounted() {
    this.getMachine();
    this.getCreditRefundReport();
  }
};
</script>